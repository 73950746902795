import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import type { TableProps } from 'antd';

interface DataType {
	email_uuid: string;
	email_to: string;
	email_from: string;
	subject: string;
	received_time: string;
}

const columns: TableProps<DataType>['columns'] = [
	{
		title: 'UUID',
		dataIndex: 'email_uuid',
		key: 'email_uuid',
		render: (text) => <a href="_">{text}</a>,
	},
	{
		title: 'From',
		dataIndex: 'email_from',
		key: 'email_from',
	},
	{
		title: 'To',
		dataIndex: 'email_to',
		key: 'email_to',
	},
	{
		title: 'Subject',
		dataIndex: 'subject',
		key: 'subject',
	},
	{
		title: 'DateTime',
		dataIndex: 'received_time',
		key: 'received_time',
	},
];

const perPage: number = 10;

export function TableEmail() {
	const [emails, setEmails] = useState();

	const [pagination, setPagination] = useState({
		page: 0,
		per_page: perPage,
		count: 0,
		total_count: 0,
	});
	const [currentPage, setCurrentPage] = useState<number>(1);

	const getEmails = (page: number) => {
		fetch(`/api/emails?page=${page}&per_page=${perPage}`)
			.then((res) => res.json())
			.then(
				(result) => {
					setEmails(result.result);
					setPagination(result.result_info);
				},
				(error) => {
					console.error(`Error loading tasks: ${error.toString()}`);
				},
			);
	};

	useEffect(() => {
		if (emails && pagination.page === currentPage) {
			return;
		}
		console.log('pagination:', currentPage, pagination);
		getEmails(currentPage);
	}, [pagination, currentPage]);

	return (
		<Table
			columns={columns}
			dataSource={emails || []}
			pagination={{
				position: ['bottomCenter'],
				hideOnSinglePage: true,
				showSizeChanger: false,
				showQuickJumper: false,
				current: pagination.page,
				pageSize: perPage,
				total: pagination.total_count,
				onChange: (page) => {
					console.log('change: page:', page);
					setCurrentPage(page);
				},
			}}
		/>
	);
}
