import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Button, Result } from 'antd';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import { TableEmail } from './components/Emails/TableEmails';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const NotFound = () => {
	return (
		<Result
			status="404"
			title="404"
			subTitle="Sorry, the page you visited does not exist."
			extra={
				<Button type="primary" href="/">
					Back Home
				</Button>
			}
		/>
	);
};
const Home = () => {
	return (
		<div>
			<p>Email</p>
		</div>
	);
};

//root.render(<App />);

const routes = [
	{
		path: '/',
		element: <App />,
		errorElement: <NotFound />,
		children: [
			{
				path: '/',
				element: <Home />,
			},
			{
				path: '/emails',
				element: <TableEmail />,
			},

			//...tasks,
			//...catalog,
		],
	},
];

root.render(<RouterProvider router={createBrowserRouter(routes)} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
